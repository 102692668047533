@tailwind base;
@tailwind components;
@tailwind utilities;

/* Keyframes for continuous scrolling */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Scrolling animation */
.animate-scroll {
  display: flex;
  width: max-content; /* Ensures the content is wide enough */
  animation: scroll 20s linear infinite; /* Adjust speed as necessary */
}

/* Keyframes for pulsing animation */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.7;
  }
}

/* Apply the pulsing effect */
.pulse {
  animation: pulse 1.5s infinite;
}

/* Badge rotation when visible */
.rotate-45 {
  transform: rotate(45deg);
  transition: transform 0.5s ease; /* Smooth rotation */
}


/* Add this CSS to your main stylesheet or inline in LandingPage */
.bg-dotted {
  background-color: #fefaf4;
  background-image: radial-gradient(rgba(184, 184, 184, 0.3) 1px, transparent 1px);
  background-size: 10px 10px; /* Adjust size for spacing */
}

/* Add this CSS to your main stylesheet or inline in LandingPage */
.bg-grid {
  background-color: #fefaf4; /* Base background color */
  background-image: 
    linear-gradient(rgba(184, 184, 184, 0.15) 1px, transparent 1px), /* Horizontal lines */
    linear-gradient(90deg, rgba(184, 184, 184, 0.15) 1px, transparent 1px); /* Vertical lines */
  background-size: 20px 20px; /* Adjust size for grid spacing */
}
